import React, { useState, useEffect } from 'react';
import { coreGet, corePost, corePut } from '../api/core';
import { Cart, CartItem } from './types';
import { isValid, isAfter, addMonths, format, differenceInSeconds, addHours } from 'date-fns';
import useMediaQuery from '../hooks/useMediaQuery';

type ContextConfig = {
  loading: boolean;
  loadingCart: boolean;
  showCart: boolean;
  isPaymentPage: boolean;
  setIsPaymentPage: React.Dispatch<React.SetStateAction<boolean>>;
  addVariantToCart: (variantId: string, quantity: string) => void;
  updateLineItem: (cartId: string, lineItemID: string, quantity: string) => void;
  removeLineItem: (cartId: string, lineItemId: string) => void;
  cart: Cart;
  modalData: ModalLocalStorageInfo;
  setModalData: React.Dispatch<React.SetStateAction<ModalLocalStorageInfo>>;
  updateModalLocalStorageInfo: (currentModalData: ModalLocalStorageInfo) => void;
  showFirstModal: boolean;
  setShowFirstModal: React.Dispatch<React.SetStateAction<boolean>>;
  showSecondModal: boolean;
  setShowSecondModal: React.Dispatch<React.SetStateAction<boolean>>;
  sendEcommerceEvent: (event: string, params?: Record<string, unknown>, checkout?: boolean) => void;
};

type ProviderProps = {
  children: JSX.Element;
};

type CartLocalStorageInfo = {
  id: string | null;
  first?: string;
  last?: string;
};

type ModalLocalStorageInfo = {
  firstModalShown?: boolean;
  secondModalShown?: boolean;
};

const defaultValues: ContextConfig = {
  loading: false,
  loadingCart: false,
  showCart: false,
  isPaymentPage: false,
  setIsPaymentPage: null,
  addVariantToCart: () => {},
  updateLineItem: () => {},
  removeLineItem: () => {},
  cart: {
    items: [],
    subTotal: 0
  },
  modalData: {
    firstModalShown: true,
    secondModalShown: true
  },
  setModalData: null,
  updateModalLocalStorageInfo: () => {},
  showFirstModal: false,
  setShowFirstModal: null,
  showSecondModal: false,
  setShowSecondModal: null,
  sendEcommerceEvent: () => {}
};

export const StoreContext = React.createContext<ContextConfig>(defaultValues);

const isBrowser = typeof window !== `undefined`;
export const cartLocalStorageKey = `zoho_cart_id`;
export const modalsLocalStorageKey = `payments_modals`;

const HOURS_TO_SHOW_SECOND_MODAL = 12;
const MS_TO_SHOW_FIRST_MODAL_MOBILE = 5000;

const getCartLocalStorageInfo = (): CartLocalStorageInfo => {
  const localStorageInfo = localStorage.getItem(cartLocalStorageKey);
  try {
    return JSON.parse(localStorageInfo || '{}');
  } catch {
    return { id: localStorageInfo || null };
  }
};

const getModalLocalStorageInfo = (): ModalLocalStorageInfo => {
  const localStorageInfo = localStorage.getItem(modalsLocalStorageKey);
  try {
    return JSON.parse(localStorageInfo);
  } catch {
    return defaultValues.modalData;
  }
};

const isCartValid = (localStorageInfo: CartLocalStorageInfo): boolean => {
  if (localStorageInfo.id && localStorageInfo.id !== null) {
    const firstDate = new Date(localStorageInfo.first);
    const lastDate = new Date(localStorageInfo.last);
    const now = new Date();

    if (isValid(firstDate) && isValid(lastDate)) {
      return isAfter(addMonths(lastDate, 1), now);
    }
    return false;
  } else {
    return false;
  }
};

const updateSubTotal = (items: CartItem[]) => {
  const subTotal = items.reduce((acc, item) => {
    return acc + item.sellingPrice * item.quantity;
  }, 0);
  return subTotal;
};

export const StoreProvider: React.FC<ProviderProps> = ({ children }) => {
  const [cart, setCart] = useState(defaultValues.cart);
  const [loading, setLoading] = useState(false);
  const [loadingCart, setLoadingCart] = useState(false);
  const [showCart, setShowCart] = useState(defaultValues.showCart);
  const [isPaymentPage, setIsPaymentPage] = useState(defaultValues.isPaymentPage);
  const [modalData, setModalData] = useState<ModalLocalStorageInfo>(defaultValues.modalData);
  const [showFirstModal, setShowFirstModal] = useState(false);
  const [firstModalTimeoutId, setFirstModalTimeoutId] = useState(null);
  const [showSecondModal, setShowSecondModal] = useState(false);

  const isMobile = useMediaQuery('(max-width: 767px)');

  const createCart = (cart: Cart) => {
    localStorage.setItem(
      cartLocalStorageKey,
      JSON.stringify({
        id: cart.cartId,
        first: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
        last: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx")
      })
    );
    cart.subTotal = updateSubTotal(cart.items);
    setCart(cart);
  };

  const updateCartLocalStorageInfo = () => {
    const localStorageInfo = localStorage.getItem(cartLocalStorageKey);
    const parsedCart: CartLocalStorageInfo = JSON.parse(localStorageInfo);

    localStorage.setItem(
      cartLocalStorageKey,
      JSON.stringify({
        id: cart.cartId,
        first: parsedCart.first,
        last: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx")
      })
    );
  };

  const deleteCartLocalStorageInfo = () => {
    localStorage.removeItem(cartLocalStorageKey);
    setCart(defaultValues.cart);
  };

  const updateModalLocalStorageInfo = (currentModalData: ModalLocalStorageInfo) => {
    setModalData(currentModalData);
    localStorage.setItem(
      modalsLocalStorageKey,
      JSON.stringify({
        firstModalShown: currentModalData.firstModalShown,
        secondModalShown: currentModalData.secondModalShown
      })
    );
  };

  useEffect(() => {
    const getLocalStorageData = async () => {
      const existingCartData = isBrowser ? getCartLocalStorageInfo() : { id: null };
      let existingCart = { data: { cart: defaultValues.cart, error: '' } };
      try {
        if (isCartValid(existingCartData)) {
          existingCart = await coreGet(`shop/checkout/${existingCartData.id}`);
          if (!existingCart.data.error) {
            const resCart = existingCart.data.cart;
            resCart.subTotal = updateSubTotal(resCart.items);
            setCart(resCart);
          }
        }
      } catch (e) {
        console.error(e);
        localStorage.setItem(cartLocalStorageKey, JSON.stringify({ id: null }));
        setLoading(false);
      }

      const existingModalData = isBrowser ? getModalLocalStorageInfo() : defaultValues.modalData;

      if (existingModalData) setModalData(existingModalData);
      else setModalData({ firstModalShown: false, secondModalShown: false });

      if (
        existingCart?.data?.cart?.items.length > 0 &&
        (!existingModalData || (existingModalData && !existingModalData.secondModalShown))
      ) {
        const currentDate = new Date();
        const secondModalDate = addHours(
          new Date(existingCartData.first),
          HOURS_TO_SHOW_SECOND_MODAL
        );

        const additionalData = existingModalData
          ? existingModalData
          : { firstModalShown: false, secondModalShown: false };

        if (isAfter(currentDate, secondModalDate)) {
          setShowSecondModal(true);
          updateModalLocalStorageInfo({ ...additionalData, secondModalShown: true });
        } else {
          const secondsToShowModal = differenceInSeconds(secondModalDate, currentDate);
          setTimeout(() => {
            setShowSecondModal(true);
            updateModalLocalStorageInfo({ ...additionalData, secondModalShown: true });
          }, secondsToShowModal * 1000);
        }
      }
    };
    getLocalStorageData();
  }, []);

  useEffect(() => {
    setShowCart(!!(cart.items.length > 0 || isPaymentPage));
  }, [isPaymentPage, cart.items.length]);

  useEffect(() => {
    if (
      cart.items.length > 0 &&
      isMobile &&
      !modalData.firstModalShown &&
      !modalData.secondModalShown &&
      !firstModalTimeoutId
    ) {
      const newTimeoutId = setTimeout(function () {
        setShowFirstModal(true);
        updateModalLocalStorageInfo({ ...modalData, firstModalShown: true });
      }, MS_TO_SHOW_FIRST_MODAL_MOBILE);
      setFirstModalTimeoutId(newTimeoutId);
    }
  }, [cart.items, isMobile, modalData, firstModalTimeoutId]);

  const addVariantToCart = async (variantId: string, quantity: string) => {
    try {
      setLoading(true);
      const cartId = cart.cartId;

      const res = await corePost('shop/cart', {
        cartId: cartId ? cartId : '',
        productVariantId: variantId,
        quantity: quantity
      });

      if (res.error) {
        console.error('Shop endpoint error: ', res.error);
      } else {
        if (!cartId) {
          createCart(res.data);
        } else {
          clearTimeout(firstModalTimeoutId);
          setFirstModalTimeoutId(null);
          res.data.subTotal = updateSubTotal(res.data.items);
          setCart(res.data);
          updateCartLocalStorageInfo();
        }
      }

      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const updateLineItem = async (cartId: string, variantId: string, quantity: string) => {
    try {
      setLoadingCart(true);

      const res = await corePut('shop/cart', {
        cartId: cartId ? cartId : '',
        productVariantId: variantId,
        quantity: quantity
      });

      if (res.error) {
        console.error('Shop endpoint error: ', res.error);
      } else {
        setCart(res.data);
        res.data.subTotal = updateSubTotal(res.data.items);
        updateCartLocalStorageInfo();

        if (res.data.items.length === 0) {
          deleteCartLocalStorageInfo();
        }

        clearTimeout(firstModalTimeoutId);
        setFirstModalTimeoutId(null);
      }

      setLoadingCart(false);
    } catch (err) {
      setLoadingCart(false);
    }
  };

  const removeLineItem = async (cartId: string, variantId: string) => {
    try {
      setLoadingCart(true);
      const res = await corePut('shop/cart', {
        cartId: cartId ? cartId : '',
        productVariantId: variantId,
        quantity: '0'
      });

      if (res.error) {
        console.error('Shop endpoint error: ', res.error);
      } else {
        setCart(res.data);
        res.data.subTotal = updateSubTotal(res.data.items);
        updateCartLocalStorageInfo();

        if (res.data.items.length === 0) {
          deleteCartLocalStorageInfo();
        }

        clearTimeout(firstModalTimeoutId);
        setFirstModalTimeoutId(null);
      }

      setLoadingCart(false);
    } catch (err) {
      setLoadingCart(false);
    }
  };

  const sendEcommerceEvent = (event: string, params?: Record<string, unknown>, checkout = true) => {
    if (window.dataLayer) {
      const ecommerce = checkout
        ? {
            value: cart.subTotal,
            items: cart.items.map(item => ({
              item_id: item.sku,
              item_name: item.name,
              price: item.sellingPrice,
              quantity: item.quantity
            }))
          }
        : {};
      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push({
        event,
        ecommerce: { ...ecommerce, ...params }
      });
    }
  };

  return (
    <StoreContext.Provider
      value={{
        ...defaultValues,
        addVariantToCart,
        updateLineItem,
        removeLineItem,
        cart,
        loading,
        loadingCart,
        showCart,
        isPaymentPage,
        setIsPaymentPage,
        modalData,
        setModalData,
        updateModalLocalStorageInfo,
        showFirstModal,
        setShowFirstModal,
        showSecondModal,
        setShowSecondModal,
        sendEcommerceEvent
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};
